import React, {useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {post_api_call, error_api_call} from '../../actions//action-creators/common';
import HubListComp from './list';
import { LoaderMedium } from '../loader';
import { alertResponseMessage } from '../../actions/action-creators/response';
import ModalHubInfo from '../modal/portfolio/hub-info';

function HubListScrollComp() {
    const [hubLoading, setHubLoader] = useState(true);
    const [hubMasterList, setHubMasterData] = useState([]);
    const [hubInfoLoading, setHubInfoLoader] = useState(true);
    const [hubModalDetails, setHubModalData] = useState({});
    const [hubInfoDetails, setHubInfoData] = useState({});
    const [modalToggle, setModalToggle] = useState({
        hub_info: false
    });
    const responsiveSlide = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4,
          slidesToSlide: 4,
          partialVisibilityGutter: hubMasterList.length === 4 ? 0 : 20
        },
        desktop: {
          breakpoint: { max: 3000, min: 1400 },
          items: 3,
          slidesToSlide: 3,
          partialVisibilityGutter: hubMasterList.length === 3 ? 0 : 20
        },
        tablet: {
          breakpoint: { max: 1400, min: 850 },
          items: 3,
          slidesToSlide: 3,
          partialVisibilityGutter: hubMasterList.length === 3 ? 0 : 15
        },
        bigMobile: {
            breakpoint: { max: 850, min: 464 },
            items: 2,
            slidesToSlide: 2,
            partialVisibilityGutter: hubMasterList.length === 2 ? 0 : 15
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        //   partialVisibilityGutter: hubMasterList.length === 1 ? 0 : 15
            partialVisibilityGutter: 0
        }
      };
    //Get Data for Hub
    const fetchHubList = async() => {
        try{
			const hubApiData = await post_api_call('opportunities', {});
			if(hubApiData.status === 200){
				setHubMasterData(hubApiData.data);
			}
            setHubLoader(false);
        }catch(e){
			error_api_call({error: `component|hub|fetchHubList|${e}`});
        }
    }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, index='', form_type='') => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
        }catch(error){
            console.log(error)
        }
    }
    useEffect(() => {
        fetchHubList();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    const openHubModal = async(e, oppertunity_id) => {
        try{
            e.preventDefault();
            // Show Loader
            setHubInfoLoader(true);
            //Open Modal
            toggleModal(e, 'hub_info', true);
            //Fetch Data
            if(typeof hubInfoDetails[oppertunity_id] !== "undefined"){
                setHubModalData({...hubInfoDetails[oppertunity_id]});
            }else{
                const hubApiData = await post_api_call(`opportunity/details/${oppertunity_id}`, {});
                if(hubApiData.status === 200){
                    setHubModalData({...hubApiData.data});
                    setHubInfoData({...hubInfoDetails, [oppertunity_id]: hubApiData.data});
                }else{
                    alertResponseMessage({alert_type: 2, message: "Error loading data."});
                }
            }
            setHubInfoLoader(false);
        }catch(error){
            console.log(error)
            alertResponseMessage({alert_type: 2, message: "Error loading data."});
            error_api_call({error: `components/hub|openHubModal|${error}`});
        }
    }

    return (
        <>
        {hubLoading
        ?
            <div className="text-center mt-5 mb-5">
                <LoaderMedium size={45} />
            </div>
        :
            <div className="row">
                <div className="hub-content mb-5">
                    <Carousel
                        containerClass="carousel-container"
                        partialVisible={true}
                        responsive={responsiveSlide}
                        ssr={true} // means to render carousel on server-side.
                        // centerMode={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        infinite={false}
                        renderDotsOutside={true}
                        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        // autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        // dotListClass="custom-dot-list-style"
                        itemClass="item"
                    >
                        {hubMasterList.map((hub_data, hub_index) => (
                            <div key={`hub_${hub_index}`} className="caro-content">
                                <HubListComp
                                    data={hub_data}
                                    openHubModal={(e, hub_id) => openHubModal(e, hub_id)}
                                />
                                {/* <a href="#" onClick={(e) => { openHubModal(e, hub_data.id)}}>
                                    <div className="d-flex mb-3">
                                        <div className="img-logo">
                                            <img src={hub_data.logo} alt={hub_data.title} />
                                        </div>
                                        <p className="heading ms-2">{hub_data.title}</p>
                                    </div>
                                    <p className="sub-content">
                                        {hub_data.age_details.map((age_d, age_index) => (
                                            <span key={`hub_age${age_index}`}>
                                                {age_d.label}
                                                {age_index !==hub_data.age_details.length - 1 && (<>, &nbsp;</>)}
                                            </span>
                                        ))}
                                    </p>
                                    <p className="sub-content">Expiry: {moment(hub_data.expiry_at).format("Do MMM YYYY")}</p>
                                </a> */}
                            </div>
                        ))}
                        {/* <div></div> */}
                    </Carousel>
                </div>
            </div>
        }
        {/* For Showing Hub Info */}
        <ModalHubInfo
            isShow={modalToggle.hub_info}
            toggleModal={(e, state) => toggleModal(e, 'hub_info', state)}
            loader={hubInfoLoading}
            data={hubModalDetails}
        />
        </>
    );
  }
  
  export default HubListScrollComp;