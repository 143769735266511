import React from 'react';
import moment from 'moment';
import ShareIconsComp from '../form/share';
import {linkHttp} from '../../helper/custom-function';
import HubAppluButton from './apply-button';

const HubDetailComp = (props) => {
    
    return (
        <>
           <div className="ms-2 me-2">
                <div className="card">
                <div className="row">
                    <div className={`${props.data.expiry_days > 0 ? 'col-md-10': 'col-md-12'} col-12 col-sm-12 order-1 order-md-0`}>
                        <div className="d-flex">
                            {props.data.logo && (
                                <div className="p-0 me-3 hub-logo">
                                    <img src={props.data.logo} className="img-thumbnail" alt={`${props.data.title}`} />
                                </div>
                            )}
                            <div>
                                <h2 className="mb-1">
                                    <strong style={{fontSize: 21}}>{props.data.title}</strong>
                                    {props.data.key_benefit && (<p className="country mb-0">{props.data.key_benefit}</p>)}
                                    <p className="country mb-0">Expiry: <span className="fw-normal color-black">{moment(props.data.expiry_at).format("Do MMM YYYY")}</span></p>
                                    <p className="country mb-0">Days to Expiry:
                                        {props.data.expiry_days > 0
                                            ? <><span className="fw-normal color-black"> {props.data.expiry_days} {props.data.expiry_days > 1 ? 'Days' : 'Day'}</span></>
                                        : <span className="color-red"> Expired</span>}
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                    {props.data.expiry_days > 0 && (
                    <div className="col-md-2 col-12 col-sm-12 order-0 order-md-1">
                        <div className="float-end">
                            {/* <a href={linkHttp(props.data.apply_link)} target="_blank" rel="noopener noreferrer"><button className="btn btn-primary bg-color-blue p-2 pb-1 pt-1">Apply Now</button></a> */}
                            <HubAppluButton
                                apply_link={linkHttp(props.data.apply_link)}
                                url={props.data.url}
                            />
                        </div>
                    </div>
                    )}
                </div>
                {props.data.expiry_days > 0 && (
                <div className="row">
                    <div className="col-md-12 col-12 col-sm-12">
                        <p className="mb-0 mt-2 mt-md-0 float-end">
                            Share via:&nbsp;
                            <ShareIconsComp
                                title="Hello, I came across this Opportunity! Hope it will be helpful to You. Apply Now, "
                                link={`https://${window.location.hostname}/hub/${props.data.url}`}
                                subject={`Apply to this Opportunity: ${props.data.title}`}
                            />
                        </p>
                    </div>
                </div>
                )}
                </div>
                <div className="card">
                <div className="row mt-2 mb-3">
                    <p className="mb-0">Description:</p>
                    <div className="col-md-6 col-12 col-sm-12">
                        {/* Age */}
                        {props.data.age_details && props.data.age_details.length > 0 && (
                        <div className="mt-2">
                            <span className="t-light">Age:</span>
                            <div className="same-row">
                                {props.data.age_details.map((age_d, age_index) => (
                                    <p key={`hub_age${age_index}`} className="round-box">
                                        {age_d.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        )}
                        {/* Type */}
                        {props.data.type_details && props.data.type_details.length > 0 && (
                        <div className="mt-3">
                            <span className="t-light">Opportunity Type:</span>
                            <div className="same-row">
                                {props.data.type_details.map((type_d, type_index) => (
                                    <p key={`hub_type${type_index}`} className="round-box">
                                        {type_d.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        )}
                        {/* Duration */}
                        {props.data.duration && (
                        <div className="mt-3">
                            <span className="t-light">Duration:</span>
                            <div>
                                <p className="round-box">
                                    {props.data.duration}
                                </p>
                            </div>
                        </div>
                        )}
                        {/* Course */}
                        {props.data.course_details && props.data.course_details.length > 0 && (
                        <div className="mt-3">
                            <span className="t-light">This Opportunity for:</span>
                            <div className="same-row">
                                {props.data.course_details.map((course_d, course_index) => (
                                    <p key={`hub_course${course_index}`} className="round-box">
                                        {course_d.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12 col-sm-12">
                        {/* Mode */}
                        {props.data.mode_details && props.data.mode_details.length > 0 && (
                        <div className="mt-2">
                            <span className="t-light">Mode:</span>
                            <div className="same-row">
                                {props.data.mode_details.map((mode_d, mode_index) => (
                                    <p key={`hub_mode${mode_index}`} className="round-box">
                                        {mode_d.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        )}
                        {/* Benefits */}
                        {props.data.benefit_details && props.data.benefit_details.length > 0 && (
                        <div className="mt-3">
                            <span className="t-light">Benefits of Applying:</span>
                            <div className="same-row">
                                {props.data.benefit_details.map((benefit_d, benefit_index) => (
                                    <p key={`hub_benefit${benefit_index}`} className="round-box">
                                        {benefit_d.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        )}
                        {/* Fee */}
                        {props.data.fee && (
                        <div className="mt-3">
                            <span className="t-light">Fees/Charges:</span>
                            <div>
                                <p className="round-box">
                                    {props.data.fee}
                                </p>
                            </div>
                        </div>
                        )}
                        {(props.data.fee_id !== 6 && props.data.amount) && (
                        <div className="mt-3">
                            <span className="t-light">Amount:</span>
                            <div>
                                <p className="round-box">
                                {props.data.currency_type === 1 ? '₹' : '$'} {props.data.amount}
                                </p>
                            </div>
                        </div>
                        )}
                    </div>
                {/* </div> */}
                {/* <div className="row mt-3"> */}
                    <div className="col-md-12 col-12 col-sm-12">
                    {props.data.eligibility && (
                        <div className="mt-3">
                            <span className="t-light">Eligibility:</span>
                            <p>{props.data.eligibility}</p>
                        </div>
                    )}
                    {props.data.description && (
                        <div className="mt-3">
                            <span className="t-light">Description:</span>
                            <div className="ms-1" dangerouslySetInnerHTML={ { __html: props.data.description}}></div>
                        </div>
                    )}
                    {props.data.is_reserved && props.data.is_reserved !== 11 && (
                        <div className="mt-3">
                            <span className="t-light">Is Reserved for IFSPD Europe Members?:</span>
                            <p className="ms-1">{props.data.reserved_value}</p>
                        </div>
                    )}
                    {props.data.reservation_details && (
                        <div className="mt-3">
                            <span className="t-light">Details of Reservation:</span>
                            <div className="ms-1" dangerouslySetInnerHTML={ { __html: props.data.reservation_details}}></div>
                        </div>
                    )}
                    {props.data.imp_rule && (
                        <div className="mt-3">
                            <span className="t-light">Important Application Rules:</span>
                            <div className="ms-1" dangerouslySetInnerHTML={ { __html: props.data.imp_rule}}></div>
                        </div>
                    )}
                    {(props.fetch_type
                        && props.fetch_type === 'page'
                        && props.data.expiry_days > 0
                    ) &&(
                        <p className="text-center">
                        {/* <a href={linkHttp(props.data.apply_link)} target="_blank" rel="noopener noreferrer"><button className="btn btn-primary bg-color-blue p-2 pb-1 pt-1">Apply Now</button></a> */}
                            <HubAppluButton
                                apply_link={linkHttp(props.data.apply_link)}
                                url={props.data.url}
                            />
                        </p>
                    )}
                    </div>
                </div>
                </div>
                {props.data.expiry_days > 0 && (
                <div className="mt-4">
                    <p className="ps-md-2 mb-md-0">
                    Share via:&nbsp;
                    <ShareIconsComp
                        title="Hello, I came across this Opportunity! Hope it will be helpful to You. Apply Now, "
                        link={`https://${window.location.hostname}/hub/${props.data.url}`}
                        subject={`Apply to this Opportunity: ${props.data.title}`}
                    />
                    </p>
                </div>
                )}
            </div>
        </>
    );
  }

export {
    HubDetailComp
};