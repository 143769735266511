import React from 'react';
import { useSelector} from 'react-redux';

function HubAppluButton(props) {
    //Check if Logged In
    var userReduxData = useSelector(state => state.auth);
    userReduxData = userReduxData && userReduxData.user_info ? JSON.parse(userReduxData.user_info) : null;

    return (
      <>
      {(localStorage.getItem("token") && userReduxData)
        ?
            <a href={props.apply_link} target="_blank" rel="noreferrer"><button className="btn btn-primary bg-color-blue p-2 pb-1 pt-1" type="button">Apply Now</button></a>
        :
            <a href={`/login?redirect_uri=/hub/${props.url}`}><button className="btn btn-primary bg-color-blue p-2 pb-1 pt-1" type="button">Apply Now</button></a>
        }
      </>
    );
  }
  
  export default HubAppluButton;