import React from "react";
import {SetMetaData} from './../../helper/page-title';
import {
    Col,
    Row
  } from 'react-bootstrap';
import './../../assets/css/pages/static.css';

function GloscoLanding(props){
    SetMetaData("Glosco-Global Sustainability Councils", "", "");

    return (
        <div className={`fellow-overview fellow-campaign mt-50 glosco-cont`}>
        <div className="container-fluid">
        {/* <div className="section-header section-header-1">
            <h1 className="title">Privacy Policy</h1>
        </div> */}
            <Row className="justify-content-center">
                <Col md="12" sm="12" className="p-0">
                    <div className="card img-wrapper">
                        <img src={require("../../assets/images/bg/glosco.jpg") } className="card-img d-none d-sm-block p-0" alt="Fellowship"/>
                        {/* <div className="card-img-overlay" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}> */}
                        <div className="card-img-overlay" style={{backgroundColor: "#ffffff61"}}>
                            {/* <div className="row m-2"> */}
                                {/* <div className="col-md-3 col-4 col-sm-3 text-center text-sm-start">
                                    <img className="header-image" alt="International Foundation for Sustainable Peace and Development" src={require('../../assets/images/main/logo/ifspd_logo_m.png')} title="International Foundation for Sustainable Peace and Development" />
                                </div> */}
                                {/* <div className="col-md-9 col-8 col-sm-9">
                                    <div className="float-sm-end text-center text-sm-start">
                                        <p className="banner-head">LeadCap Ventures</p>
                                        <p className="banner-title mb-3">Research | Analytics | Consulting</p>
                                    </div>
                                </div> */}
                            {/* </div> */}
                            <div className="row">
                                <div className="col-md-12 col-12 col-sm-12 text-center">
                                    <h1 className="page-title">Global Sustainability Councils (Glosco)</h1>
                                    {/* <h2 className="page-sub-title">ifspd presidential fellowships</h2> */}
                                </div>
                            </div>
                            <div className="row justify-content-center text-center text-uppercase justify-content-center mt-2 mt-md-3 mt-xl-5 fellow-f-btn ms-3 me-3">
                                {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-5"> */}
                                    {/* <div className="col-12 col-md-12 col-sm-12 col-xl-12"><div className="m-2"><p className="box-blue1 mb-0 box-camp-1">Every year IFSPD Europe selects 10 schools from each of our member countries to be part of our flagship youth initiative – Global Sustainability Councils. This is a higher order, structured, continuous capacity enhancement program for students from 6th grade upwards, focused on the UN sustainable Development Goals.<br/>Alumni include UN National Heads, Senators, Presidential Advisors, CEOs, Senior Economists, Technocrats, Researchers & Ivy League students. Over 50% qualify to the top colleges with scholarships.</p></div></div> */}
                                    {/* <div className="col-12 col-md-6 col-sm-6 col-xl-5"><div className="m-2"><p className="box-blue2 mb-0">20,000 applicants from 60+ countries</p></div></div> */}
                                {/* </div> */}
                                {/* <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercase mt-2 mt-xxl-5 mb-5"> */}
                                {/* <div className="col-md-12 col-xl-10 col-12 col-sm-12 text-uppercases mb-5">
                                    <div className="m-2 text-center"><p className="box-grey mb-0">Alumni include senators, bureaucrats, CEOs, entrepreneurs, winners of global awards & attendees of Ivy League universities</p></div>
                                </div> */}
                                {/* <div className="col-12 col-md-12 col-sm-12 col-xl-12"><a href="https://admin.gosurvey.in/survey/PF24" target='_blank' rel="noreferrer"><div className="m-2"><p className="box-purple">APPLY TODAY<img className="header-image" alt="Apply Now" src={require('../../assets/images/fp/click.png')} title="Apply Now" /></p></div></a></div> */}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        {/* <div className="ms-3 me-3"> */}
        <div className="container">
            <Row className="justify-content-center mt-5 fellow-feature">
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Elevated-Profile.png')} alt="Elevated Profile & Global Credibility" />
                    <p className="f-title">Elevated Profile & Global Credibility</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Premium-Certification.png')} alt="Premium Global Certification"/>
                    <p className="f-title">Premium Global Certification</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Unique-Skills.png')} alt="Unique Skills & Competency Certification"/>
                    <p className="f-title">Unique Skills & Competency Certification</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Reference-Letter.png')} alt="Reference Letter from IFSPD President"/>
                    <p className="f-title">Reference Letter from IFSPD President</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/leadership.png')} alt="Leadership & Strategy Skills"/>
                    {/* <p className="f-title">Reference Letter from IFSPD President</p> */}
                    <p className="f-title">Leadership & Strategy Skills</p>
                </Col>
            {/* </Row> */}
            {/* <Row className="justify-content-center mt-3 mt-lg-5 fellow-feature"> */}
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Global-Issues.png')} alt="Global Issues Analysis & Stakeholder Management"/>
                    <p className="f-title">Global Issues Analysis & Stakeholder Management</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Critical-Thinking.png')} alt="Critical Thinking & Diplomacy Skills"/>
                    <p className="f-title">Critical Thinking & Diplomacy Skills</p>
                </Col>
                <Col md="3" sm="6" xs="6" className="text-center">
                    <img src={require('../../assets/images/fp/fp1/Profile-Page.png')} alt="IFSPD Professional Profile Page"/>
                    <p className="f-title">IFSPD Professional Profile Page</p>
                </Col>
            </Row>
        </div>
        <div className="container compact">
            <Row className="justify-content-center text-center text-uppercase">
                {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-4"> */}
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 pe-1 ps-1"><div><p className="box-blue1 mb-0 mt-2 mt-md-0">accelerated career path</p></div></div>
                    <div className="col-12 col-md-6 col-sm-6 col-xl-6 pe-1 ps-1"><div><p className="box-grey mb-0 mt-2 mt-md-0">improved college admission chances</p></div></div>
                {/* </div> */}
            </Row>
            <Row className="justify-content-center text-center text-uppercase mt-0 mt-lg-2">
                <div className="col-md-12 col-12 col-sm-12 col-xl-12 text-uppercase justify-content-center">
                    <Row>
                    <div className="col-12 col-md-3 col-sm-6 col-xl-3 pe-1 ps-1"><div className="box-blue1 mt-2 mt-md-0"><span>Students</span></div></div>
                    <div className="col-12 col-md-3 col-sm-6 col-xl-3 pe-1 ps-1"><div className="box-grey mt-2 mt-md-0"><span>professionals</span></div></div>
                    <div className="col-12 col-md-3 col-sm-6 col-xl-3 pe-1 ps-1"><div className="box-blue2 mt-2 mt-md-0"><span>bureaucrats</span></div></div>
                    <div className="col-12 col-md-3 col-sm-6 col-xl-3 pe-1 ps-1"><div className="box-grey2 mt-2 mt-md-0"><span>researchers</span></div></div>
                    </Row>
                </div>
            </Row>
            {/* fellowship details */}
            <Row className="justify-content-center mt-50 fellow-feature ps-3 pe-3 ps-sm-0 pe-sm-0">
                <h3 className="heading">FELLOWSHIP DETAILS</h3>
                <div className="plan-table p-0 table-responsive" style={{position: "relative"}}>
                    <table border="0">
                        <thead>
                            <tr>
                                <th></th>
                                <th><p className="bg-color-blue">fast track</p></th>
                                <th><p className="bg-color-d-blue">intensive</p></th>
                                <th><p className="bg-color-grey">full fledged</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p className="t-title">Duration</p></td>
                                <td className="content">4 weeks online</td>
                                <td className="content">8 weeks online</td>
                                <td className="content">12 weeks online</td>
                            </tr>
                            <tr>
                                <td><p className="t-title">Benefits</p></td>
                                <td className="content">
                                    <ol>
                                        <li>Premium Global Certificate (digital)</li>
                                        <li>Unique Skills Report</li>
                                        <li>Leadership, Analysis & Strategy Skills</li>
                                        <li>IFSPD Profile Page Mention.</li>
                                    </ol>
                                </td>
                                <td className="content">
                                    <ol>
                                        <li>Premium Global Certificate (digital)</li> 
                                        <li>Unique Skills Report</li>
                                        <li>Leadership, Analysis & Strategy Skills</li>
                                        <li>IFSPD Profile Page Mention.</li>
                                    </ol>
                                </td>
                                <td className="content">
                                    <ol>
                                        <li>Premium Global Certificate</li>
                                        <li>Unique Skills Report</li>
                                        <li>Leadership, Analysis,  Strategy & Diplomatic Skills</li>
                                        <li className="color-blue">Curated IFSPD Profile Page</li>
                                        <li className="color-blue">IFSPD Special Certification of Unique Skills</li>
                                        <li className="color-blue">Personal Recommendation Letter from the IFSPD President.</li>
                                    </ol>
                                </td>
                            </tr>
                            <tr>
                                <td><p className="t-title">Fees</p></td>
                                <td className="content">$400 or Rs.29,800</td>
                                <td className="content">$600 or Rs.49,800</td>
                                <td className="content">$750 or Rs.59,800</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tick-icon">
                        <img className="p-0 m-0" src={require("../../assets/images/tick.png")} alt="Tick" loading="lazy" />
                    </div>
                </div>
            </Row>
            {/* methodology */}
            <Row className="justify-content-center mt-50 program-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                <h3 className="heading">program methodology</h3>
                <div className="col-12 col-md-6 ps-0">
                   <div className="content">
                        <p className="t-title">presidential briefings (PB)</p>
                        <p className="t-desc color-blue">Leading subject matter experts fuel introspection, investigation and influence - to spark innovative solutions and bridge the differences in our world.</p>
                        <img className="bottom" src={require("../../assets/images/fp/mike.jpg")} alt="Press conference" loading="lazy" />
                   </div>
                </div>
                <div className="col-12 col-md-6 pe-0 pt-5 pt-md-0">
                   <div className="content">
                        <img className="top d-none md-d-block" src={require("../../assets/images/fp/69qGPzCsOYXSC28j35QGVg==.jpg")} alt="Online Video Conference Webinar Call" loading="lazy" />
                        <p className="t-title">global Strategy councils (GSC)</p>
                        <p className="t-desc color-blue">Discussing, debating, synthesising and reaching conclusions from multiple stakeholder perspectives on critical global issues.</p>
                        <img className="top d-block md-d-none" src={require("../../assets/images/fp/69qGPzCsOYXSC28j35QGVg==.jpg")} alt="Online Video Conference Webinar Call" loading="lazy" />
                   </div>
                </div>
            </Row>
            {/* button: Start */}
            <Row className="justify-content-center text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                {/* <div className="col-md-12 col-12 col-sm-12 d-md-flex text-uppercase justify-content-center mt-2 mt-lg-4"> */}
                    <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0"><div className="bg-color-d-blue box-blue1 mb-0 mt-2"><p className="font-25">week 1 - 4</p><p>onboarding, PB followed by debrief, gSc</p></div></div>
                    <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0"><div className="bg-color-blue box-blue1 mb-0 mt-2"><p className="font-25">week 5 -8</p><p>pb followed by debrief, gSc</p></div></div>
                    <div className="col-12 col-md-4 col-sm-6 col-xl-4 ps-0 pe-md-0"><div className="bg-color-grey2 box-grey mb-0 mt-2"><p className="font-25">week 8 - 12</p><p>pb followed by debrief, GSC, research paper</p></div></div>
                {/* </div> */}
            </Row>
            <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                <div className="col-4 col-md-4 col-sm-4 col-xl-4 ps-0"><div className="bg-color-d-blue c-box mb-0 mt-2"><p>FAST TRACK</p></div></div>
            </Row>
            <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                <div className="col-8 col-md-8 col-sm-8 col-xl-8 ps-0"><div className="bg-color-blue c-box mb-0 mt-2"><p>INTENSIVE</p></div></div>
            </Row>
            <Row className="text-center text-uppercase week-btn-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                <div className="col-12 col-md-12 col-sm-12 col-xl-12 ps-0 pe-md-0"><div className="bg-color-grey2 c-box mb-0 mt-2"><p>FULL FLEDGED</p></div></div>
            </Row>
            {/* button: End */}
            {/* ALUMNI */}
            <Row className="justify-content-center mt-50 alumni-sec ps-3 pe-3 ps-sm-0 pe-sm-0">
                <h3 className="heading">ALUMNI SPOTLIGHT</h3>
                <div className="col-12 col-md-3 text-center">
                   <div className="content">
                        {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                            <img src={require("../../assets/images/fp/jU68rwDOXlk6A95jp-YHXA.png")} loading="lazy" alt="Charles chimdiya" />
                        {/* </a> */}
                        <p className="t-name color-blue">Charles chimdiya</p>
                        <p className="t-position">Senior Policy Expert</p>
                        <p className="t-country color-blue">Nigeria</p>
                   </div>
                </div>
                <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                   <div className="content">
                        {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                            <img src={require("../../assets/images/fp/1YOkcQEqnWnFZDHX9zQo7w==.png")} loading="lazy" alt="Nayab Zahra" />
                        {/* </a> */}
                        <p className="t-name color-blue">Nayab Zahra</p>
                        <p className="t-position">CEO, SD Nexus</p>
                        <p className="t-country color-blue">switzerland</p>
                   </div>
                </div>
                <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                   <div className="content">
                        {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                            <img src={require("../../assets/images/fp/GaKhtUBwyt1uvZwYNhR2Lg.png")} loading="lazy" alt="Yash Singh Sisodia" />
                        {/* </a> */}
                        <p className="t-name color-blue">Yash Singh Sisodia</p>
                        <p className="t-position">Sr Researcher, LBSNAA</p>
                        <p className="t-country color-blue">INDIA</p>
                   </div>
                </div>
                <div className="col-12 col-md-3 mt-5 pt-5 mt-md-0 pt-md-0 text-center">
                   <div className="content">
                        {/* <a href="/fellow-profiles" target='_blank' rel="noreferrer" title="Fellow Profiles"> */}
                            <img src={require("../../assets/images/fp/Xnn9Ou2tExJf4U2fy4ZF4A==.png")} loading="lazy" alt="Etape Ngole" />
                        {/* </a> */}
                        <p className="t-name color-blue">Etape Ngole</p>
                        <p className="t-position">Senator, UN</p>
                        <p className="t-country color-blue">cameroon</p>
                   </div>
                </div>
            </Row>
            {/* Apply Now */}
            <Row className="justify-content-center text-center week-btn-sec">
                <div className="col-12 col-md-8 col-sm-6 col-xl-8">
                <p className="color-grey2 heading-grey">elevate your profile</p>
                <a href="https://admin.gosurvey.in/survey/PF24" target='_blank' rel="noreferrer"><div className="m-2"><p className="box-purple mb-3">APPLY TODAY<img className="header-image" alt="Apply Now" src={require('../../assets/images/fp/click.png')} title="Apply Now" /></p></div></a>
                </div>
                <p className="font-20">Rolling applications review.</p>
                <a href="mailto:director@ifspd.eu" title="Email Address" className="font-20 f-weight-700 text-decoration-underline mb-4">Contact: director@ifspd.eu</a>
            </Row>
        </div>
        {/* </div> */}
    </div>
    )    
}

export default GloscoLanding;