import React from 'react';

const CompletedSecUI = (props) => {
    
    return (
        <>
            {/* <span className="w-100"> */}
            <div className="d-flex justify-content-between w-100">
                <p className="mb-0"><i className="fa fa-edit me-2"></i>{props.title}</p>
                {(typeof props.section === "undefined" && props.section !== 'skill')
                    ?
                        props.data.indexOf(props.form_id) !== -1 && (
                            <p className="mb-0"><i className="fa fa-check-circle me-2 com"></i></p>
                        )
                    :
                        (props.data.indexOf(props.form_id[0]) !== -1 || props.data.indexOf(props.form_id[1]) !== -1) && (
                            <p className="mb-0"><i className="fa fa-check-circle me-2 com"></i></p>
                        )
                }
            </div>
            {/* </span> */}
        </>
    );
  }

export {
    CompletedSecUI
};