import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../assets/css/pages/form.css';
import { alertResponseMessage } from "../../actions/action-creators/response";
// import { SpinnerRoundFilled } from 'spinners-react';
import {LoaderMedium} from './../../components/loader';
//Axios Call
import {error_api_call, post_api_call} from '../../actions/action-creators/common';
import {SetMetaData} from './../../helper/page-title';
const initialSelectData = {
  validity: "12",
  auto_renew: true
};

const PaymentPage = () => {
  SetMetaData("Payment", "", "");
  // const {state} = useLocation();
  // const { redirect_uri } = state;
  const location = useLocation();
  const [pageLoading, setPageLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [planData, setPlanData] = useState({});
  const [planApiData, setPlanApiData] = useState({});
  const [priceData, setPriceData] = useState({});
  const [expiryData, setExpiryData] = useState({});
  const [selectedPlanData, setSelectedData] = useState(initialSelectData);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [redirectUri, setRedirectUri] = useState('');
  //Redirection Url
  useEffect(() => {
    const urlHandling = () => {
      try{
        // const urlQuryString = window.location.search;
        const urlQuryString = location.search;
        const urlParam = new URLSearchParams(urlQuryString);
        const urlRedirect = urlParam.get('redirect_uri');
        if(urlRedirect !== '' && urlRedirect !== null){
          setRedirectUri(urlRedirect);
        }
      }catch(e){
        error_api_call({error: `auth/login|urlHandling|${e}`});
      }
    }
    urlHandling();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Initial Data
  useEffect(() => {
    const fetchPlanDetails = async() => {
      try{
        const planApiData = await post_api_call(`payment-plan`, {});
        console.log(planApiData, 'planApiData')
        if(planApiData.status === 200){
          var planDetails = {};
          planApiData.data.plan_details.forEach(plan_d => {
            planDetails[plan_d.service_id] = {...plan_d,
                                              price_display: `${plan_d.currency_type === 1 ? `₹` : `$`} ${plan_d.price}`
                                              }
          });
          //Expiry Details
          // var expiryDetails = {};
          // planApiData.data.plan_details.forEach(plan_d => {
          //   planDetails[plan_d.service_id] = {...plan_d,
          //                                     price_display: `${plan_d.currency_type === 1 ? `₹` : `$`} ${plan_d.price}`
          //                                     }
          // });
          setPlanData({...planDetails});
          setExpiryData({...planApiData.data.expiration_date});
          setPlanApiData({...planApiData.data});
          setPageLoader(false);
        }
      }catch(e){
        error_api_call({error: `payment/landing|fetchPlanDetails|${e}`});
      }
    }
    fetchPlanDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  //Open Checkout Modal
  const showCheckoutModal = (event, service_id, state=true) => {
    try{
      if(service_id !== 0){
        event.preventDefault();
      }
      setShowModal(state);
      setSelectedData(state ? {...selectedPlanData, ...planData[service_id]} : {...initialSelectData});
      if(state){
        // _priceCalculation();
      }else{
        setPriceData({});
      }
    }catch(e){
      console.log(e)
      error_api_call({error: `payment/landing|showCheckoutModal|${e}`});
    }
  }
  //Update Selected Plan
  const _updateSelectedPlan = (input, field) => {
    try{
      if(field === 'service_id'){
        setSelectedData({...selectedPlanData, ...planData[input]});
      }else{
        setSelectedData({...selectedPlanData, [field]: input});
      }
      //Price Calculation
      // _priceCalculation();
    }catch(e){
      console.log(e)
      error_api_call({error: `payment/landing|_updateSelectedPlan|${e}`});
    }
  }
  //Calculate Price
  // const _priceCalculation = () => {
  //   try{
  //     //Price Calculation
  //     console.log(selectedPlanData.validity/12, 'lllllllllllllllllllllllllllllllll')
  //     const price = (parseInt(selectedPlanData.validity)/12)*selectedPlanData.price;
  //     const gst = planApiData.price_details.gst;
  //     const platformCharge = planApiData.price_details.platform_charges;
  //     const gstCal = gst > 0 ? (gst/100)*price : 0;
  //     const platformChargeCal = platformCharge > 0 ? (platformCharge/100)*price : 0;
  //     const total = parseInt(price) + parseInt(gstCal) + parseInt(platformChargeCal);
  //     setPriceData({gst: gstCal, platform_fee: platformChargeCal, total, price});
  //   }catch(e){
  //     console.log(e)
  //     error_api_call({error: `payment/landing|_priceCalculation|${e}`});
  //   }
  // }
  
  useEffect(() => {
		//Get Filtered SStartup
    const _priceCalculation = () => {
      try{
        //Price Calculation
        const price = ((parseInt(selectedPlanData.validity)/12)*selectedPlanData.price).toFixed(2);
        const gst = planApiData.price_details.gst;
        const platformCharge = planApiData.price_details.platform_charges;
        const gstCal = gst > 0 ? parseFloat((gst/100)*price).toFixed(2) : 0;
        const payAmount = parseFloat(price) + parseFloat(gstCal);
        const platformChargeCal = platformCharge > 0 ? parseFloat((platformCharge/100)*payAmount).toFixed(2) : 0;
        const total = parseFloat(price) + parseFloat(gstCal) + parseFloat(platformChargeCal);
        setPriceData({gst: gstCal, platform_fee: platformChargeCal, total, price});
      }catch(e){
        alertResponseMessage({alert_type: 2, message: "Error Loading Details! please try again"});
        console.log(e)
        error_api_call({error: `payment/landing|_priceCalculation|${e}`});
      }
    }
		if(selectedPlanData.service_id){
			_priceCalculation();
		}
    console.log('jjjjjj', selectedPlanData)
	}, [selectedPlanData]);// eslint-disable-line react-hooks/exhaustive-deps
  //To check date
  const isDate = (val) => {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]';
  }
  //To check Object
  const isObj = (val) => {
    return typeof val === 'object';
  }
  //Stringify Data
  const stringifyValue = (val) => {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  //Build Paytm Payment Form
  const buildForm = ({ action, params }) => {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
  
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
  
    return form;
  }
  //Redirect to Paytm Gateway
  const payuPageRedirection = (details) => {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }
  //checkout
  const _checkout = async(e) => {
      try{
        e.preventDefault();
        setCheckoutLoader(true);
        // return;
        const placeOrderData = await post_api_call(`payment/generate`, {...selectedPlanData,
                                                                        amount: priceData.total,
                                                                        expiry_date: expiryData[`port_${selectedPlanData.validity}`]
                                                                      });
        // console.log(placeOrderData, 'placeOrderData.status')
        // return;
        if(placeOrderData.status === 200){
          payuPageRedirection({
            action: process.env.REACT_APP_PAYU_URL,
            // action: 'https://test.payu.in/_payment',
            // action: 'https://secure.payu.in/_payment',
            params: placeOrderData.data
          });
        }else{
          setCheckoutLoader(false);
        }
      }catch(error){
        setCheckoutLoader(false);
        alertResponseMessage({alert_type: 2, message: "Something Went wrong, Please try after some times"});
        error_api_call({error: `payment/landing|_checkout|${error}`});
      }
  }

  return (
    <>
    <main id="main">
        <section className="mt-60 pt-4">
            <div className="container-fluid container-lg">
                <div className="section-header section-header-1">
                    <h1 className="title">Subscription Plans</h1>
                </div>       
                {/* <div className="card"> */}
                    <div className="row payment-plan justify-content-center">
                    {pageLoading 
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                    <>
                      {/* <a href={state && state.redirect_uri ? state.redirect_uri : '/port'}><p className="skip-text">Skip for Now</p></a> */}
                      {/* <a href={redirectUri ? redirectUri : '/port'}><p className="skip-text">Skip for Now</p></a> */}
                      {redirectUri
                        ? <a href={redirectUri}><p className="skip-text">Skip for Now</p></a>
                        : 
                          <div className="ps-2 pe-2">
                          <div className="alert alert-danger text-center p-1 mb-5">
                            <p className="fs-5 mb-0 p-1"><b>Your subscription has expired! Please renew it.</b></p>
                          </div>
                          </div>
                      }
                      <p className="fs-3 mb-3 text-center fw-bold"><span className="color-blue">Pick your perfect plan</span></p>
                      <div className="col-12 col-md-4 col-sm-8">
                          <div className="card text-center p-3 mb-4">
                              <h6 className="color-blue fs-5"><b>{planData[1].name}</b></h6>
                              <div className="content">
                              <p className="text-black">Create a finely curated student portfolio - tracking achievements, showcasing growth and differentiating from the crowd.</p><br/>
                              <p><i>Free Competency & Career Mapping worth $100</i></p>
                              {/*{planData[1].country_type === 1 && (
                                <p className="color-red">Subsidized India Fee:</p>
                              )}*/}
                              <p>12 months for <b>{planData[1].price_display}</b> &nbsp;{planData[1].mrp > planData[1].price && (<span className="strike">{planApiData.price_details?.currency} {planData[1].mrp}</span>)}</p>
                              </div>
                              <button
                                className="btn btn-primary bg-color-blue fw-bold"
                                onClick={(e) => showCheckoutModal(e, 1)}
                              >Subscribe Now</button>
                          </div>
                      </div>
                      {/* <div className="col-12 col-md-4 col-sm-8">
                          <div className="card text-center p-3 mb-4">
                              <h6 className="color-blue fs-5"><b>{planData[2].name}</b></h6>
                              <div className="content">
                              <p className="text-black">Enabling students to gain real world start-up skills, learn leadership and team working, create social impact and boost college applications.</p><br/><br/>
                              <p><i></i></p>
                              {planData[2].currency_type === 1 && (
                                <p className="color-red">Subsidized India Fee:</p>
                              )}
                              <p>12 months for <b>{planData[2].price_display}</b> &nbsp;{planData[2].mrp > planData[2].price && (<span className="strike">{planApiData.price_details?.currency} {planData[2].mrp}</span>)}</p>
                              </div>
                              <button
                                className="btn btn-primary bg-color-blue fw-bold"
                                onClick={(e) => showCheckoutModal(e, 2)}
                              >Subscribe Now</button>
                          </div>
                      </div>
                      <div className="col-12 col-md-4 col-sm-8">
                          <div className="card text-center p-3 mb-4">
                              <h6 className="color-blue fs-5"><b>{planData[3].name}</b></h6>
                              <div className="content">
                              <p className="text-black">Gaining real world start-up skills & social impact, while keeping track of achievements; enabling an elevated profile.</p><br/>
                              <p><i>Free Competency & Career Mapping worth $100</i></p>
                              {planData[3].currency_type === 1 && (
                                <p className="color-red">Subsidized India Fee:</p>
                              )}
                              <p>12 months for <b>{planData[3].price_display}</b> &nbsp;{planData[3].mrp > planData[3].price && (<span className="strike">{planApiData.price_details?.currency} {planData[3].mrp}</span>)}</p>
                              </div>
                              <button
                                className="btn btn-primary bg-color-blue fw-bold"
                                onClick={(e) => showCheckoutModal(e, 3)}
                              >Subscribe Now</button>
                          </div>
                      </div> */}
                    </>
                  }
                  </div>
                {/* </div> */}
            </div>
        </section>
    </main>
    {/* Payment Details Modal */}
    <Modal
      show={showModal}
      onHide={(e) => showCheckoutModal(e, 0, false)}
      // size="md"
      className="pay-modal"
      dialogClassName="modal-custom-wid"
      backdrop="static"
    > 
      {!checkoutLoader && (
        <Modal.Header closeButton>
        {/* <Modal.Title> */}
          {/* Competencies & Career Mapping */}
        {/* </Modal.Title> */}
        </Modal.Header>
      )}
      <Modal.Body>
        <div className="detail justify-content-between">
          <div className="desc">
            <h5>Subscription Plan</h5>
            <p>Review the details and proceed to checkout</p>
            <p className="d-flex"><span className="color-blue">Plan:</span>&nbsp;&nbsp;
              <select
                className="border-0 w-100"
                value={selectedPlanData.service_id}
                onChange={(e) => _updateSelectedPlan(e.target.value, 'service_id')}
                disabled
              >
                {Object.keys(planData).map((plan_key) => (
                  <option key={`plan-${plan_key}`} value={planData[plan_key].service_id}>{planData[plan_key].name}</option>
                ))}
              </select>
            </p>
            <p className="mb-0 d-flex"><span className="color-blue">Validity:</span>&nbsp;&nbsp;
              <select
                className="border-0 w-100"
                value={selectedPlanData.validity}
                onChange={(e) => _updateSelectedPlan(e.target.value, 'validity')}
              >
                <option value="48">48 months</option>
                <option value="36">36 months</option>
                <option value="24">24 months</option>
                <option value="12">12 months</option>
              </select>
            </p>
          </div>
          <div className="">
            <p className="mb-0 price">{selectedPlanData.price_display}<small>/Year</small></p>
          </div>
        </div>
        <div className="p-2 mt-4 pay-info">
          <table className="mb-0 border-0">
              <tbody>
                <tr>
                    <th>Expiration Date</th>
                    <td className="text-end">
                      {selectedPlanData.service_id === 1
                        ?
                          <span>{moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                        :
                          selectedPlanData.service_id === 2
                            ?
                              <span>{moment(expiryData[`ss_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                            :
                              selectedPlanData.service_id === 3
                              ?
                                expiryData[`port_${selectedPlanData.validity}`] === expiryData[`ss_${selectedPlanData.validity}`]
                                  ?
                                    <span>{moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                                  :
                                    <span>Port: {moment(expiryData[`port_${selectedPlanData.validity}`]).format("Do MMM YYYY")} <br/>Social Start-up: {moment(expiryData[`ss_${selectedPlanData.validity}`]).format("Do MMM YYYY")}</span>
                              : <></>
                      }
                    </td>
                </tr>
                <tr>
                    <th>Fee</th>
                    <td className="text-end"><span>{planApiData.price_details?.currency} {priceData.price}</span></td>
                  </tr>
                {(planApiData.price_details?.gst > 0) && (
                  <tr>
                    <th>Taxes&nbsp;
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 0, hide: 0 }}
                        overlay={<Tooltip>Taxes include 18% GST</Tooltip>}
                      ><i className="fa fa-info-circle"></i></OverlayTrigger>
                    </th>
                    <td className="text-end"><span>{planApiData.price_details?.currency} {priceData.gst}</span></td>
                  </tr>
                )}
                {(planApiData.price_details?.platform_charges > 0) && (
                <tr>
                    <th>Platform Charges</th>
                    <td className="text-end"><span>{planApiData.price_details?.currency} {priceData.platform_fee}</span></td>
                  </tr>
                )}
                <tr>
                    <th><b>Total to Pay</b></th>
                    <td className="text-end"><span><b>{planApiData.price_details?.currency} {priceData.total}</b></span></td>
                </tr>
              </tbody>
          </table>
          <div className="note p-1 mt-3">
            {/* <p className="mb-0 fw-lighter"><b>Expiration Date</b>: 1 Year + 3 days <b>FREE</b> are included with this subscription.</p> */}
            <p className="mb-0 fw-lighter"><b>Expiration Date</b>: Existing validity will add up to the new Expiration Date, if any.</p>
          </div>
        </div>
        <p className="mb-0 mt-2 text-end">
          <input
            className="form-check-input"
            type="checkbox"
            id="auto_renew"
            value={selectedPlanData.auto_renew}
            checked={selectedPlanData.auto_renew}
            onChange={(e) => _updateSelectedPlan(e.target.checked, 'auto_renew')}
          />
          <label className="form-check-label" htmlFor="auto_renew">&nbsp;Auto Renew</label>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <p className="term">By checking out, you agree with our <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms of Service</a> and confirm that you have read our <a href="/privacy-policy" target="_blank" >Privacy Policy</a>.</p>
        <div className="d-flex">
          {!checkoutLoader && (
            <div className="d-none d-sm-flex">
              <button className="btn btn-secondary me-2" onClick={(e) => showCheckoutModal(e, '', false)}>Cancel</button>
            </div>
          )}
          {checkoutLoader
            ?
              <button className="btn btn-primary bg-color-blue" disabled type="submit"><LoaderMedium width={20} /> Redirecting to Payment Page...</button>
            :
            <button
              className="btn btn-primary bg-color-blue"
              type="submit"
              onClick={(e) => _checkout(e)}
            >Choose payment method</button>
          }
        </div>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default PaymentPage
